import {format, distanceInWords, differenceInDays} from 'date-fns'
import React from 'react'
import BlockContent from './block-content'
import Container from './container'
import serializers from './serializers'

import styles from './blog-post.module.css'

function BlogPost (props) {
  const {_rawBody, categories, title, publishedAt, readingTimeInMinutes} = props
  return (
    <article className={styles.root}>
      {/* {mainImage && mainImage.asset && (
        <div className={styles.mainImage}>
          <img
            src={imageUrlFor(buildImageObj(mainImage))
              .width(1200)
              .height(Math.floor((9 / 16) * 1200))
              .fit('crop')
              .url()}
            alt={mainImage.alt}
          />
        </div>
      )} */}
      <Container>
        <div className={styles.grid}>
          <div className={styles.mainContent}>
            <h1 className={styles.title}>{title}</h1>
            {_rawBody && <BlockContent blocks={_rawBody} serializers={serializers} />}
          </div>
          <aside className={styles.metaContent}>
            {publishedAt && (
              <div className={styles.publishedAt}>
                {differenceInDays(new Date(publishedAt), new Date()) > 3
                  ? distanceInWords(new Date(publishedAt), new Date())
                  : format(new Date(publishedAt), 'MMMM Do YYYY')}
                {readingTimeInMinutes && (
                  <React.Fragment>
                    <br />
                    Reading time: {readingTimeInMinutes} mins
                  </React.Fragment>
                )}
              </div>
            )}

            {categories && (
              <div className={styles.categories}>
                <h3 className={styles.categoriesHeadline}>Categories</h3>
                <ul>
                  {categories.map(category => (
                    <li key={category._id}>{category.title}</li>
                  ))}
                </ul>
              </div>
            )}
          </aside>
        </div>
      </Container>
    </article>
  )
}

export default BlogPost
